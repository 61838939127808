import React, { useState } from "react";
import { departements } from "../models/departements";
import { idArray, sports } from "../models/racetypes";

export default function RaceFilter(props) {
  const [sport, setSport] = useState("");
  const [distance, setDistance] = useState("");
  const [departement, setDepartement] = useState("");
  const [date, setDate] = useState("");
  let sportsMap = new Map(sports);

  const handleClick = e => {
    // like idArray[sport] but for objects
    let sportObject = idArray
      .filter(s => s.sport === sport)
      .reduce((acc, value) => {
        return value;
      }, null);
    if (sportObject) {
      let typeId = sportObject.id;
      let distanceIds = [];
      if (distance !== "") {
        distanceIds = sportObject.distances
          .filter(d => d.label === distance)
          .map(d => d.id);
      } else {
        //send all distances
        distanceIds = sportObject.distances.map(d => d.id);
      }
      props.onFilter(typeId, distanceIds, departement, date);
    } else {
      props.onFilter("", [], departement, date);
    }
  };
  return (
    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <div className="select is-primary">
            <select onChange={e => setSport(e.target.value)}>
              <option value="">Tous Sports</option>
              {sports.map(s => (
                <option value={s[0]} key={s[0]}>
                  {s[0]}
                </option>
              ))}
            </select>
          </div>
          &nbsp;
        </div>
        <div className="level-item">
          <div className="select is-primary">
            <select onChange={e => setDistance(e.target.value)}>
              <option value="">Toutes Distances</option>
              {sport &&
                sportsMap.get(sport).map(d => (
                  <option value={d} key={d}>
                    {d}
                  </option>
                ))}
            </select>
          </div>
          &nbsp;
        </div>
        <div className="level-item">
          <div className="select is-primary">
            <select onChange={e => setDepartement(e.target.value)}>
              <option value="">Département</option>
              {departements.map(d => (
                <option value={d.departmentCode} key={d.departmentCode}>
                  {d.departmentCode + " - " + d.departmentName}
                </option>
              ))}
            </select>
          </div>
          &nbsp;
        </div>
        <div className="level-item">
          <input
            className="input is-primary"
            type="date"
            onChange={e => setDate(e.target.value)}
          />
          &nbsp;
        </div>
        <div className="level-item">
          <button
            className="button is-light"
            type="button"
            value="next"
            onClick={handleClick}
          >
            <i className="fas fa-search" value="next"></i>
          </button>
          <br />
        </div>
      </div>
    </nav>
  );
}
