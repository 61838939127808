let triDistances = [
  { label: "S", id: 1 },
  { label: "M", id: 2 },
  { label: "L", id: 3 },
  { label: "XL", id: 4 },
  { label: "XXL", id: 5 },
  { label: "XS", id: 6 },
  { label: "Jeunes", id: 7 }
];

// translates names in cards
export let displayNames = new Map([
  ["Course à pied", "Course"],
  ["Autre Catégorie", ""]
])

export let idArray = [
  {
    id: 1,
    sport: "Triathlon",
    distances: triDistances
  },
  {
    id: 2,
    sport: "Course à pied",
    distances: [
      { label: "5km", id: 21 },
      { label: "10km", id: 22 },
      { label: "Semi", id: 23 },
      { label: "Marathon", id: 24 },
      { label: "Autre Catégorie", id: 25 }
    ]
  },
  {
    id: 5,
    sport: "Swimrun",
    distances: triDistances
  },
  {
    id: 6,
    sport: "Swimbike",
    distances: triDistances
  },
  {
    id: 7,
    sport: "Duathlon",
    distances: triDistances
  },
  {
    id: 8,
    sport: "Trail",
    distances: [
      { label: "Découverte", id: 81 },
      { label: "Court", id: 82 },
      { label: "Long", id: 83 },
      { label: "Ultra", id: 84 },
      { label: "Autre Catégorie", id: 85 }
    ]
  },
  {
    id: 9,
    sport: "Bike and Run",
    distances: triDistances
  },
  {
    id: 10,
    sport: "Aquathlon",
    distances: triDistances
  },
  {
    id: 11,
    sport: "Cross Triathlon",
    distances: triDistances
  },
  {
    id: 12,
    sport: "Cross Duathlon",
    distances: triDistances
  }
];

// for map[[triathlon,[S, M, L]],[run, [5km, 10km, semi, marathon]]]
export let sports = idArray.map(s => {
  let dArray = [];
  s.distances.forEach(d => dArray.push(d.label));
  return [s.sport, dArray];
});
