import React from "react";
import { displayNames } from "../models/racetypes";

function RaceCard(props) {
  const displayValue = value => {
    let display = displayNames.get(value);
    if (display !== undefined) {
      return display;
    } else {
      return value;
    }
  };

  return (
    <div
      className="column is-one-third"
      onClick={() => {
        if (props.race.website) window.open(props.race.website, "_blank");
      }}
    >
      <div className="notification border-hover">
        <article className="tile is-ancestor">
          <div className="tile is-parent is-paddingless">
            <div className="tile is-parent is-vertical">
              <div className="tile is-child is-marginless">
                <strong>
                  <span
                    className="has-text-primary is-pulled-right"
                    style={{ height: "80px" }}
                  >
                    {new Intl.DateTimeFormat("en-GB").format(
                      Date.parse(props.race.start_time.substring(0, 10))
                    )}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: props.race.title }}
                  ></span>
                </strong>
              </div>
              <div className="tile is-child">
                {displayValue(props.race.types[0].type_name) + " "}
                {props.race.types
                  .map(t => displayValue(t.distance_name))
                  .reduce((a, b) => {
                    return a + ", " + b;
                  })}
                <div>
                  {props.race.city}&nbsp;
                  {props.race.zip}
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}

export default RaceCard;
