import React from "react";

export default function PageNavigation(props) {
  const handleClick = e => {
    if (props.onClick) {
      props.onClick(e);
    }
  };
  return (
    <div className="is-pulled-right">
      <button
        className="button is-light"
        type="button"
        value="previous"
        onClick={handleClick}
        disabled={!props.previous}
      >
        <i className="fas fa-angle-left" value="previous"></i>
      </button>
      &nbsp;
      <button
        className="button is-light"
        type="button"
        value="next"
        onClick={handleClick}
        disabled={!props.next}
      >
        <i className="fas fa-angle-right" value="next"></i>
      </button>
    </div>
  );
}
