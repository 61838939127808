import React from "react";
import RaceList from "./racelist";
import ReactGA from "react-ga";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-152822470-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  return (
    <React.Fragment>
      <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Planning sportif</h1>

            <h2 className="subtitle">Trouvez vos prochaines courses et organisez votre saison</h2>
          </div>
        </div>
      </section>
      <section className="section">
        <RaceList />
      </section>
    </React.Fragment>
  );
}

export default App;
