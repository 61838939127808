import React, { useState, useEffect } from "react";
import RaceCard from "./racecard";
import RaceFilter from "./racefilter";
import PageNavigation from "./navigation";

export default function RaceList(props) {
  const [races, setRaces] = useState([]);
  const [cursors, setCursors] = useState({ next: null, previous: null });
  const [raceType, setRaceType] = useState({ typeId: null, distanceIds: [] });
  const [departement, setDepartement] = useState("");
  const [createdAt, setCreatedAt] = useState(new Date());

  useEffect(() => {
    fetchRaces(new Headers());
  }, [raceType, departement, createdAt]);

  const navigateRaces = e => {
    var h = new Headers();
    if (e.currentTarget.value === "next" && cursors.next !== null)
      h.append("cursor", cursors.next);
    else if (e.currentTarget.value === "previous" && cursors.previous !== null)
      h.append("cursor", cursors.previous);

    fetchRaces(h);
  };

  const filterRaces = (type, distances, departement, date) => {
    setRaceType({ typeId: type, distanceIds: distances });
    setDepartement(departement);
    let d = new Date(Date.parse(date));
    if (d.toString() !== "Invalid Date") {
      setCreatedAt(d);
    }
  };

  const fetchRaces = h => {
    let queryParam = "";
    if (raceType.typeId) {
      queryParam = "?type_id=" + raceType.typeId;
      queryParam += raceType.distanceIds.reduce((acc, value) => {
        return acc + "&distance_id=" + value;
      }, "");
    }

    if (departement) {
      if (queryParam) {
        queryParam += "&dep=" + departement;
      } else {
        queryParam += "?dep=" + departement;
      }
    }

    if (!h.has("cursor")) {
      if (queryParam) {
        queryParam += "&created_at=" + createdAt.toISOString();
      } else {
        queryParam += "?created_at=" + createdAt.toISOString();
      }
    }

    fetch(process.env.REACT_APP_BASE_API + "/races" + queryParam, { headers: h })
      .then(response => {
        setCursors({
          next: response.headers.get("Next-Cursor"),
          previous: response.headers.get("Previous-Cursor")
        });
        return response.json();
      })
      .then(data => {
        setRaces(data);
      });
  };

  return (
    <div className="container ">
      <RaceFilter onFilter={filterRaces} />
      <PageNavigation
        onClick={navigateRaces}
        next={cursors.next}
        previous={cursors.previous}
      />
      <div className="columns is-multiline">
        {races.map(r => (
          <RaceCard key={r.id} race={r} />
        ))}
      </div>
      <PageNavigation
        onClick={navigateRaces}
        next={cursors.next}
        previous={cursors.previous}
      />
    </div>
  );
}
